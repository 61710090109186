import { title } from "@/@core/utils/filter";

export default [
  {
    header: "Data",
  },
  {
    title: "Inspiration",
    route: "inspiration",
    icon: "SunIcon",
  },
  {
    title: "Tanya Master",
    icon: "MessageCircleIcon",
    children: [
      {
        title: "Tanya Master List",
        route: "tanya-master",
      },
    ],
  },
  {
    title: "User",
    icon: "UserIcon",
    children: [
      {
        title: "User Activity",
        route: "user-activity",
      },
      {
        title: "User List",
        route: "user-list",
      },
      {
        title: "User Login",
        route: "user-login",
      }
    ],
  },
  {
    title: "Vendor",
    icon: "ArchiveIcon",
    children: [
      {
        title: "Vendor List",
        route: "vendor-list",
      },
      {
        title: "Vendor Konsultasi",
        route: "vendor-konsultasi"
      }
    ],
  },
  {
    title: "Event",
    icon: "ActivityIcon",
    children: [
      {
        title: "Aktivitas",
        route: "aktivitas",
      },
      {
        title: "Banner Content",
        route: "banner-content",
      },
    ],
  },
  {
    title: "Building Material",
    icon: "HomeIcon",
    children: [
      {
        title: "Building Material",
        route: "building-material",
      },
      {
        title: "Category",
        route: "building-material-category",
      },
      {
        title: "Floor",
        route: "building-material-floor",
      },
      {
        title: "Room",
        route: "building-material-room",
      },
      {
        title: "Pembongkaran",
        route: "building-material-pembongkaran",
      },
    ],
  },
  {
    title: "Feedback",
    route: "feedback",
    icon: "MessageCircleIcon"
  },
  {
    title: "Creator Points",
    icon: "CoinIcon",
    children: [
      {
        title: "Points",
        route: "points",
      },
      {
        title: "Points Transaction",
        route: "points-transaction",
      },
      {
        title: "Appreciation Points",
        route: "appreaciation-points"
      }
    ],
  },
];
